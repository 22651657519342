
@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-bold-webfont.eot');
    src: url('montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff'),
         url('montserrat-bold-webfont.ttf') format('truetype'),
         url('montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-extrabold-webfont.eot');
    src: url('montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-extrabold-webfont.woff2') format('woff2'),
         url('montserrat-extrabold-webfont.woff') format('woff'),
         url('montserrat-extrabold-webfont.ttf') format('truetype'),
         url('montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-semibold-webfont.eot');
    src: url('montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-semibold-webfont.woff2') format('woff2'),
         url('montserrat-semibold-webfont.woff') format('woff'),
         url('montserrat-semibold-webfont.ttf') format('truetype'),
         url('montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-medium-webfont.eot');
    src: url('montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-medium-webfont.woff2') format('woff2'),
         url('montserrat-medium-webfont.woff') format('woff'),
         url('montserrat-medium-webfont.ttf') format('truetype'),
         url('montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-regular-webfont.eot');
    src: url('montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff'),
         url('montserrat-regular-webfont.ttf') format('truetype'),
         url('montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-light-webfont.eot');
    src: url('montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-light-webfont.woff2') format('woff2'),
         url('montserrat-light-webfont.woff') format('woff'),
         url('montserrat-light-webfont.ttf') format('truetype'),
         url('montserrat-light-webfont.svg#montserratlight') format('svg');
    font-style: normal;
    font-weight: 200;
}


@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-thin-webfont.eot');
    src: url('montserrat-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-thin-webfont.woff2') format('woff2'),
         url('montserrat-thin-webfont.woff') format('woff'),
         url('montserrat-thin-webfont.ttf') format('truetype'),
         url('montserrat-thin-webfont.svg#montserratthin') format('svg');
    font-style: normal;
    font-weight: 100;
}


