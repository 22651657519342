/* You can add global styles to this file, and also import other style files */
@import url("../public/assets/fonts/montserrat/fonts.css");
@import url("../public/assets/css/reset.css");
@import url("../public/assets/css/theme.css");
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #455a64;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

body {
    overflow-x: hidden;
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}

ul {
    padding: 0px;
}

