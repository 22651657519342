
:root {
    --main-bg-color: #fff;
    --light-bg-color: #EFEEF0;
    --selected-text-color: #359AB0;
    --dark-color: #0F1319;
    --dark-highlight: #20252C;
    --color-gray: #929292;
    --border-color: #EFEEF0;
    --adhan-color: #359AB0;
    --iqamah-color: #c5a21b;
    --salah-color: #7cad30;

    /* time screen color  */
    --clock-left-block: #dddddd;
    --clock-left-block-border: #d7d5d5;
    --clock-right-block: #d2d2d2b5;
    --clock-active-time: #359ab0;
    --clock-listing-header: silver;
    --clock-text-color: #2a2b2b;
    --clock-listing-text-color: #2a2b2b;
    --clock-mm-link: #0056b3;

    /* clock color  */
    --default-color: rgb(133, 141, 150);
    --adhan-color: rgb(53, 154, 176);
    --iqamah-color: rgb(197, 162, 27);
    --salah-color: rgb(124, 173, 48);
    --adhan-highlight: rgb(40, 120, 138);
    --iqamah-highlight: rgb(154, 127, 23);
    --salah-highlight: rgb(90, 125, 34);
    --namaz-highlight: rgb(53, 154, 176);
    --black-highlight: rgb(42, 43, 43);
    --gray-highlight: rgb(90, 93, 93);
    --white-highlight: rgb(255, 255, 255);
    --hourHandColor: rgb(133, 141, 150);
    --minuteHandColor: rgb(133, 141, 150);
    --secondHandColor: rgb(200, 0, 0);

    --salahScreen-day-text-color: #7cad30;
    --salahScreen-day-background-color: #FFF;
    
    --salahScreen-night-text-color: #7cad30;
    --salahScreen-night-background-color: #FFF;
}
.light{
    --dark-color: #0F1319;
    --light-bg-color: #EFEEF0;
    --clock-right-block: #d2d2d2b5
}
.dark{
    --dark-color: #fff;
    --light-bg-color: #1f1f1f;
    --clock-right-block: #000;
    --clock-listing-text-color: #777f88;
    --clock-text-color: #777f88;
    --clock-listing-header:#1f1f1f;

}
.cyanTheme {
    --clock-left-block: #ADDDE8;
    --clock-left-block-border: #ADDDE8;
    --clock-right-block: #006B82;
    /* --clock-active-time: #ADDDE8; */
    --clock-listing-header: #ADDDE8;
    --clock-listing-text-color: #ADDDE8;
    --clock-text-color: #006B82;
    --clock-mm-link: #ADDDE8;
    --dark-color:#fff;
    --light-bg-color : #0f414c;

    --hourHandColor: #006B82;
    --minuteHandColor: #006B82;

    --salahScreen-day-text-color: #00829E;
    --salahScreen-night-text-color: #00829E
}

.cyanDarkTheme {
    --clock-left-block: #00829E;
    --clock-left-block-border: #00829E;
    --clock-right-block: #176576;
    --clock-listing-header: #00829E;
    --clock-listing-text-color: #CEE5EA;
    --clock-text-color: #CEE5EA;
    --clock-mm-link: #CEE5EA;
    --dark-color: #fff;
    --light-bg-color: #0f414c;

    --salahScreen-day-text-color: #00829E;
    --salahScreen-night-text-color: #00829E
}

.yellowTheme {
    --clock-left-block: #FFFCD4D9;
    --clock-left-block-border: #FFFCD4D9;
    --clock-right-block: #F2E641;
    --clock-listing-header: #FFFCD4D9;
    --clock-listing-text-color: #F08200;
    --clock-text-color: #F08200;
    --clock-mm-link: #F08200;
    --dark-color: #fff;
    --light-bg-color: #53330d;

    --salahScreen-day-text-color: #F08200;
    --salahScreen-night-text-color: #F08200
}

.yellowDarkTheme {
    --clock-left-block: #FFF682;
    --clock-left-block-border: #FFF682;
    --clock-right-block: #F3E856;
    --clock-listing-header: #FFF682;
    --clock-listing-text-color: #F08200;
    --clock-text-color: #F08200;
    --clock-mm-link: #F08200;
    --dark-color: #fff;
    --light-bg-color: #53330d;

    --salahScreen-day-text-color: #F08200;
    --salahScreen-night-text-color: #F08200
}

.greenTheme {
    --clock-left-block: #2F843A;
    --clock-left-block-border: #2F843A;
    --clock-right-block: #003907;
    --clock-listing-header: #2F843A;
    --clock-listing-text-color: #D7C88C;
    --clock-text-color: #D7C88C;
    --clock-mm-link: #D7C88C;
    --dark-color: #fff;
    --light-bg-color: #09280d;

    --salahScreen-day-text-color: #3cae4b;
    --salahScreen-night-text-color: #1A5922;
}
    .greenDarkTheme {
        --clock-left-block: #1A5922;
        --clock-left-block-border: #1A5922;
        --clock-right-block: #003907;
        --clock-listing-header: #1A5922;
        --clock-listing-text-color: #D7C88C;
        --clock-text-color: #D7C88C;
        --clock-mm-link: #D7C88C;
        --dark-color: #fff;
        --light-bg-color: #09280d;

        --salahScreen-day-text-color: #3cae4b;
        --salahScreen-night-text-color: #1A5922;
    }

    .tangaroaTheme {
        --clock-left-block: #90ACD1;
        --clock-left-block-border: #90ACD1;
        --clock-right-block: #041832;
        --clock-listing-header: #90ACD1;
        --clock-listing-text-color: #90ACD1;
        --clock-text-color: #041832;
        --clock-mm-link: #90ACD1;
        --dark-color: #fff;
        --light-bg-color: #062246;

        --salahScreen-day-text-color: #062246;
        --salahScreen-night-text-color: #062246;
    }

    .tangaroaDarkTheme {
        --clock-left-block: #062246;
        --clock-left-block-border: #062246;
        --clock-right-block: #041832;
        --clock-listing-header: #062246;
        --clock-listing-text-color: #96ACCA;
        --clock-text-color: #96ACCA;
        --clock-mm-link: #96ACCA;
        --dark-color: #fff;
        --light-bg-color: #062246;

        --salahScreen-day-text-color: #062246;
        --salahScreen-night-text-color: #062246;
    }

    .bismarkTheme {
        --clock-left-block: #C2DEF1;
        --clock-left-block-border: #C2DEF1;
        --clock-right-block: #456478;
        --clock-listing-header: #C2DEF1;
        --clock-listing-text-color: #C2DEF1;
        --clock-text-color: #456478;
        --clock-mm-link: #C2DEF1;
        --dark-color: #fff;
        --light-bg-color: #11354e;

        --salahScreen-day-text-color: #50738A;
        --salahScreen-night-text-color: #50738A;
    }

    .bismarkDarkTheme {
        --clock-left-block: #50738A;
        --clock-left-block-border: #50738A;
        --clock-right-block: #456478;
        --clock-listing-header: #50738A;
        --clock-listing-text-color: #C2DEF1;
        --clock-text-color: #C2DEF1;
        --clock-mm-link: #C2DEF1;
        --dark-color: #fff;
        --light-bg-color: #11354e;

        --salahScreen-day-text-color: #50738A;
        --salahScreen-night-text-color: #50738A;
    }

    .raspberryTheme {
        --clock-left-block: #FFC7E6;
        --clock-left-block-border: #FFC7E6;
        --clock-right-block: #822659;
        --clock-listing-header: #FFC7E6;
        --clock-listing-text-color: #FFC7E6;
        --clock-text-color: #822659;
        --clock-mm-link: #FFC7E6;
        --dark-color: #fff;
        --light-bg-color: #4e0f32;

        --salahScreen-day-text-color: #942C65;
        --salahScreen-night-text-color: #942C65;
    }

    .raspberryDarkTheme {
        --clock-left-block: #942C65;
        --clock-left-block-border: #942C65;
        --clock-right-block: #822659;
        --clock-listing-header: #942C65;
        --clock-listing-text-color: #FFC7E6;
        --clock-text-color: #FFC7E6;
        --clock-mm-link: #FFC7E6;
        --dark-color: #fff;
        --light-bg-color: #4e0f32;

        --salahScreen-day-text-color: #942C65;
        --salahScreen-night-text-color: #942C65;
    }